import axios from 'axios';

export const getPreferences = async (token) => {
  return await axios
    .get(
      `https://ss-pref-centre.azurewebsites.net/api/GetPreferences/${token}?code=8OpO_2jlx1eLXrmIQ7nM0evMX0q3nN6boJOomYdj_5i-AzFukhyv2A==`
    )
    .then((response) => response.data[0])
    .catch((error) => console.log(error));
};

export const updatePreferences = async (body) => {
  return await axios
    .post(
      `https://ss-pref-centre.azurewebsites.net/api/PostPreferences?code=E25qVpbmZcOgWAM0qnrClAHm2sqifhYspipvZQqLJbiwAzFukBENwg==`,
      body
    )
    .then((response) => response.data)
    .catch((error) => console.log(error));
};
