import MainPanel from './components/MainPanel';
import './App.css';
import Loading from './components/Loading';
import { getPreferences } from './components/mainPanel.service';
import { useEffect, useState } from 'react';
import NoToken from './components/NoToken';

function App() {
  const [waitCall, setWaitCall] = useState(true);
  const [data, setData] = useState();
  const [authentication, setAuthentication] = useState(false);

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      let apiData = await getPreferences(token);
      setWaitCall(false);
      setAuthentication(apiData);
      setData(apiData);
    })();
  }, []);
  return (
    <div className="App">
      {waitCall ? <Loading /> : <>{!authentication ? <NoToken /> : <MainPanel apiData={data} />}</>}
    </div>
  );
}

export default App;
